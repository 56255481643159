import React, { Component } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import "./App.css";
import BrandService from "./services/brandService";

import Login from "./components/login";
import Menu from "./components/menu";
import Logout from "./components/logout";
import NotFound from "./components/notfound";

class App extends Component {
  state = {
    brandPrefix: "hightower",
  };

  constructor(props) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);
    this.redirectUri = window.location.origin + "/implicit/callback";

    this.config = {
      authUrl: process.env.REACT_APP_AUTH_BASE_URL,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      issuer: process.env.REACT_APP_AUTH_BASE_URL + "/oauth2/default",
      redirectUri: this.redirectUri,
      onAuthRequired: this.onAuthRequired,
    };

    this.oktaAuth = new OktaAuth(this.config);
  }

  async componentDidMount() {
    LogRocket.init("mgxe1z/client-portal", {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf("/authn") >= 0) {
            if (request && request.body) request.body = null;
          }
          return request;
        },
      },
    });
    setupLogRocketReact(LogRocket);
    const brand = BrandService.getBrandClassPrefix(window.location.host);
    this.setState({ brandPrefix: brand });
    console.log(`${brand} ${process.env.NODE_ENV} ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
  }

  onAuthRequired() {
    this.props.history.push("/login");
  }

  async restoreOriginalUri(_oktaAuth, originalUri) {
    this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  render() {
    return (
      <React.Fragment>
        <Security oktaAuth={this.oktaAuth} onAuthRequired={this.config.onAuthRequired} restoreOriginalUri={this.restoreOriginalUri} tokenManager={{ storage: "sessionStorage" }}>
          <div className={`container-fluid full-height ${this.state.brandPrefix}-background`}>
            <div className="row">
              <Switch>
                <Route
                  path="/login"
                  exact
                  render={(props) => (
                    <Login
                      brandPrefix={this.state.brandPrefix}
                      authUrl={this.config.authUrl}
                      authClientId={this.config.clientId}
                      redirectUri={this.redirectUri}
                      issuer={this.config.issuer}
                      {...props}
                    />
                  )}
                />
                <SecureRoute path="/menu" exact render={(props) => <Menu brandPrefix={this.state.brandPrefix} authUrl={this.config.authUrl} {...props} />} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/implicit/callback" exact component={LoginCallback} />
                <Route path="/not-found" exact component={NotFound} />
                <Redirect from="/" exact to="/menu" />
                <Redirect exact to="/not-found" />
              </Switch>
            </div>
          </div>
        </Security>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
